#projects {
  .col {
    flex: 0 0 auto;

    &-title {
      width: calc(var(--char-width) * 35);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
        width: calc(var(--char-width) * 35);        
      }
    }

    &-client {
      flex: 0 0 auto;
      width: calc(var(--char-width) * 30);
    }

    &-type {
      flex: 0 0 auto;
      width: calc(var(--char-width) * 11);
    }

    &-date {
      flex: 0 0 auto;
      width: calc(var(--char-width) * 5);
    }
  }

  .inactive {
    color: var(--inactive-color);
  }

  a:hover {
    background-color: var(--hover-background-color);
    color: var(--hover-foreground-color);
  }
}

#appear {
  z-index: 9999999;
  background-color: black;
  width: 100%;
  height: 100vh;
  bottom: 0;

  &.partial {
    height: calc(100vh - var(--pixel-width) * 16);
    bottom: calc(var(--pixel-width) * 16);
  }
}

body[data-theme='1'],
body[data-theme='2'] {
  #projects a:hover {
    background-color: var(--hover-foreground-color);
    color: var(--hover-background-color);
  }
}
