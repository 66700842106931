* {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

body {
  font-size: 2.221vw;

  @media (max-width: 500px) {
    font-size: 4.442vw;
  }
}

p {
  max-width: calc(var(--char-width) * 60);
}