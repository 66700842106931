$border-width:                1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

$border-color:                $gray-300;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               .25rem;
$border-radius-sm:            .2rem;
$border-radius-lg:            .3rem;
$border-radius-pill:          50rem;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow:                  0 .5rem 1rem rgba($black, .15);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075);
