$primary:     blue;
$secondary:   #c0c0c0;

$theme-colors: (
  'primary':    $primary,
  'secondary':  $secondary,
  'success':    $success,
  'info':       $info,
  'warning':    $warning,
  'danger':     $danger,
  'black':      $black,
  'white':      $white,
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

$utilities-colors: map-merge(
  $theme-colors-rgb,
  (
    'black': to-rgb($black),
    'white': to-rgb($white),
    'body':  to-rgb($body-color)
  )
);

$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'text');

$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');
