$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

@include _assert-ascending($container-max-widths, '$container-max-widths');

$grid-columns:                12;
$grid-gutter-width:           1.5rem;
$grid-row-columns:            6;

$gutters: $spacers;

$container-padding-x: $grid-gutter-width * .5;
