@import '../../node_modules/simplebar/dist/simplebar.css';

#scrollbar {
  .btn-scroll-up,
  .btn-scroll-down {
    position: fixed;
    right: 0;
  }

  .btn-scroll-up {
    top: calc(var(--pixel-width) * 32);
  }

  .btn-scroll-down {
    bottom: calc(var(--pixel-width) * 16);
  }
}

.simplebar-track.simplebar-vertical {
  top: calc(var(--pixel-width) * 16);
  bottom: calc(var(--pixel-width) * 16);
  background-color: var(--alt-background-color);
  pointer-events: all !important;
  
  &::before {
    content: '▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒\A▒';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    color: var(--alt-foreground-color);
    font-size: 2.5vw;
  }
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  background-color: var(--alt-foreground-color);
  opacity: 1;
}
.simplebar-track.simplebar-vertical {
  right: calc(var(--char-width) * -1);
  width: var(--char-width);
}