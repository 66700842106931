body {
  --background-color: #{$black};
  --foreground-color: #{$black};
  --alt-background-color: #{$black};
  --alt-foreground-color: #{$black};
  --hover-background-color: #{$black};
  --hover-foreground-color: #{$black};;
  --accent-color: #{$black};
  --inactive-color: #{$black};

  background-color: var(--background-color);
  color: var(--foreground-color);
}

.bg-main {
  background-color: var(--background-color);
}

.color-alt {
  background-color: var(--alt-background-color);
  color: var(--alt-foreground-color);
}

a.active,
a.color-alt:hover,
.color-alt a:hover {
  background-color: var(--hover-background-color);
  color: var(--hover-foreground-color);
}



