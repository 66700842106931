$html-font-size: 15px;

@import 'utilities/rem';
@import 'utilities/transitions';
@import 'utilities/debug';

@import 'bootstrap/custom';

@import 'typography/fonts';
@import 'typography/typography';

@import 'structure';
@import 'colors';

//@import '../../wpml/templates/language-switchers/clean/style';

@import 'modules';
