body {
  --char-width: calc(100vw / 80);
  --pixel-width: calc(100vw / 720);

  @media (max-width: 500px) {
    --char-width: calc(100vw / 40);
    --pixel-width: calc(100vw / 360);

    font-size: 4.442vw;
  }
}

.line-vertical {
  --x-char: 0;

  position: fixed;
  top: calc(var(--pixel-width) * 23);
  left: calc(var(--char-width) * var(--x-char) + var(--pixel-width) * 3);
  height: calc(100vh - var(--pixel-width) * 18);
  border-left-color: var(--foreground-color);
  border-left-style: solid;
  border-left-width: 1;

  @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
    border-left-width: calc(var(--pixel-width) * 2);
  }

  &.line-right {
    --x-char: 39;

    @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
      --x-char: 79;      
    }
  }
}

.line-horizontal {
  --y-char: 0;
  position: fixed;
  top: calc(var(--char-width) / 0.5625 * var(--y-char) + var(--pixel-width) * 7);
  left: calc(var(--pixel-width) * 3);
  width: calc(100vw - var(--pixel-width) * 7);
  border-bottom-color: var(--foreground-color);
  border-bottom-style: solid;
  border-bottom-width: 1;

  @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
    border-bottom-width: calc(var(--pixel-width) * 1);
  }

}

.content {
  position: fixed;
  top: calc(var(--pixel-width) * 32);
  left: calc(var(--pixel-width) * 9);
  width: calc(100vw - var(--pixel-width) * 18);
  height: calc(100vh - var(--pixel-width) * 48);
}

#page-title {
  position: fixed;
  top: calc(var(--pixel-width) * 16);
  left: 50%;
  transform: translateX(-50%);
}

.mt-1 {
  margin-top: calc(var(--pixel-width) * 16) !important;
}

.mb-1 {
  margin-bottom: calc(var(--pixel-width) * 16) !important;
}