$spacer: 1rem;
$spacers: (
  0: 0,
  1: var(--char-width),
  2: calc(var(--char-width) * 2),
  3: calc(var(--char-width) * 3),
  4: calc(var(--char-width) * 4),
  5: calc(var(--char-width) * 5)
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);
